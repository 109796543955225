export const environment = {
  production: false,
  staging: true,
  deploymentEnvironment: 'Staging',
  base_url: 'https://portal-staging.easyeviction.com',
  protocol: 'https',
  domain: 'eeviction.com',
  api_prefix: 'https://api-staging.easyeviction.com/',
  hcaptcha_sitekey: '9300d912-a3d3-4ead-b17c-fe70d40b3b8d',
  google_analytics_tracking_code: 'UA-123352868-2',
  version: '7352174cfaa73eef308ffafb7c51760f4f120365',
  mapbox_public_key: 'pk.eyJ1IjoiYXN1a2hvZG9sc2t5IiwiYSI6ImNrNWdxYTl6dTA2eDkzbW4yczczaHJqbnAifQ.BYnmppleQvO8unP7HnGuWQ',
  stripe_api_key: 'pk_test_ohk5StAV9PnYEimeLOyJBA2t',
  product_tours: {
    attorney_start: 73012,
    client_start: 73014
  },
  user_roles_feature: true,
  integrations: {
    lawpay_target_url:
      'https://secure.lawpay.com/oauth/authorize?redirect_uri=' +
      'https://portal-staging.easyeviction.com/admin/plugins/connect/invoice-payment/law-pay' +
      '&client_id=964ad168b64ce071a90c1e31e07a34ec812971091fe1193eecb4acac88e51f2f&scope=payments&response_type=code',
    dropbox_target_url:
      'https://www.dropbox.com/oauth2/authorize?client_id=cuw37k0vjb35tk6&' +
      'redirect_uri=https://portal-staging.easyeviction.com/admin/plugins/connect/storage/dropbox' +
      '&response_type=code&token_access_type=offline'
  },
  client_initial_tour_id: 73008,
  attorney_initial_tour_id: 73007,
  fruits_workspace_id: 'briV1nf3UxuoVsic',
  crisp_website_id: '19e93856-6024-41bf-9875-90d2591e4a14',
  auth0_domain: 'auth-staging.easyeviction.com',
  auth0_clientid: 'ljCamvPnsmzSuwzKcP6wnISYG28YYiZl',
  auth0_audience: 'https://api-staging.easyeviction.com',
  agGridLicense:
    'Using_this_{AG_Grid}_Enterprise_key_{AG-064257}_in_excess_of_the_licence_granted_is_not_permitted___Please_report_misuse_to_legal@ag-grid.com___For_help_with_changing_this_key_please_contact_info@ag-grid.com___{Triple_Play_Solutions_LLC}_is_granted_a_{Single_Application}_Developer_License_for_the_application_{EasyEviction}_only_for_{2}_Front-End_JavaScript_developers___All_Front-End_JavaScript_developers_working_on_{EasyEviction}_need_to_be_licensed___{EasyEviction}_has_been_granted_a_Deployment_License_Add-on_for_{1}_Production_Environment___This_key_works_with_{AG_Grid}_Enterprise_versions_released_before_{28_September_2025}____[v3]_[01]_MTc1OTAxNDAwMDAwMA==a24c0e031b9d853e5818249e2441eb4b'
};
